<template>
  <div class="box">
    <input v-model="value11" class="input" />
    <i class="iconfont iconsousuo"></i>
    <div class="srea" @click="route">搜索</div>
    <!-- </input> -->
    <div class="logs">
      <span v-for="(item,index) in hotList" @click="routeOne(item.wordsName)">{{item.wordsName}}</span>
    </div>
  </div>
</template>

<script>
import api from "@/util/api";
import bus from "@/util/bus";
export default {
  data() {
    return {
      value11: this.$route.query.value || "",
      userInfo: JSON.parse(sessionStorage.getItem("userInfo") || "{}"),
      searchForm: {
        page: 1,
        pageSize: 10,
        search: {},
      },
      hotList: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.userInfo.token) {
        this.hotListFun();
      }
    });
    this.inputHot();
  },
  methods: {
     inputHot(){
       console.log(1111);
      bus.$on("inputHot", (msg)=>{
        this.value11 = msg;
      });
    },
    route() {
      this.$router.push({ path: "/search", query: { value: this.value11 } });
    },
    routeOne(name){
      this.value11 = name;
      this.$router.push({ path: "/search", query: { value: name } });
    },
    hotListFun() {
      let searchForm = this.searchForm;
      searchForm.search.type = 1;
      api.hotWordsHotWordsPage(searchForm, (res) => {
        this.hotList = res.resultList;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ivu-input {
  border: none !important;
}
input:focus {
  outline: none;
}
.box {
  width: 500px;
  height: 32px;
  position: relative;
  font-family: PingFangSC-Medium, PingFang SC;
  .input {
    width: 500px;
    height: 32px;
    border-radius: 2px;
    border: 2px solid #299654;
    text-indent: 30px;
    cursor: pointer;
  }
  .iconsousuo {
    color: #bababa;
    font-size: 16px;
    position: absolute;
    top: 4px;
    left: 8px;
  }
  .srea {
    width: 80px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: #299654;
    border-radius: 0px 2px 2px 0px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 3px;
  }
  .logs {
    position: absolute;
    top: 36;
    left: 0;
    width: 480px;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
  }
  .logs > span:nth-child(1) {
    color: #e64747;
    cursor: pointer;
  }
  .logs > span:not(:nth-child(1)) {
    margin-left: 8px;
     cursor: pointer;
  }
}
</style>