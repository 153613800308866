<template>
  <div class="footer">
    <div class="footerT">
      <div class="footerTView">友情链接</div>
      <ul class="footerUl">
        <li>
          <a href="http://www.xshts.com/" target="_blank"
            ><img src="@/assets/logo/www.xshts.com.png" alt=""
          /></a>
        </li>
        <li>
          <a href="http://www.cianyuan.com.cn/" target="_blank"
            ><img src="@/assets/logo/www.cianyuan.com.cn.png" alt=""
          /></a>
        </li>
        <li>
          <a href="http://www.tianshouyuan.com/" target="_blank"
            ><img src="@/assets/logo/www.tianshouyuan.com.png" alt=""
          /></a>
        </li>
        <li>
          <a href="http://www.xswsy.com/" target="_blank"
            ><img src="@/assets/logo/www.xswsy.com.png" alt=""
          /></a>
        </li>

        <li>
          <a href="http://www.kmxhly.com/" target="_blank"
            ><img src="@/assets/logo/www.kmxhly.com.png" alt=""
          /></a>
        </li>
        <li>
          <a href="http://www.xsmlly.com/" target="_blank"
            ><img
              src="@/assets/logo/www.xsmlly.com.png"
              alt=""
              style="width: 200px; height: 40px; margin-top: 10px"
          /></a>
        </li>
      </ul>
      <ul class="footerUl">
        <li>
          <a href="http://www.baofushan.com/" target="_blank"
            ><img
              src="@/assets/logo/www.baofushan.com.png"
              alt=""
              style="width: 200px; height: 50px; margin-top: 20px"
          /></a>
        </li>
        <li>
          <a href="http://www.kmxaly.com/" target="_blank"
            ><img
              src="@/assets/logo/www.kmxaly.com.png"
              alt=""
              style="width: 130px; height: 60px; margin-top: 10px"
          /></a>
        </li>
        <li>
          <a href="http://www.zzyqly.com/" target="_blank"
            ><img
              src="@/assets/logo/www.zzyqly.com.png"
              alt=""
              style="width: 130px; height: 60px; margin-top: 10px"
          /></a>
        </li>

        <li>
          <a href="http://www.shtssy.cn/" target="_blank"
            ><img
              src="@/assets/logo/www.shtssy.cn.png"
              alt=""
              style="width: 220px; height: 50px; margin-top: 14px"
          /></a>
        </li>
      </ul>
    </div>
    <div class="footerBox">
      <div class="footerBoxTop">
        <div class="footerBoxTopOne">
          <p><icon class="iconfont icondianhua"></icon>联系电话：</p>
          <p>400-119-0811</p>
        </div>
        <div class="footerBoxTopTwo">
          <p><icon class="iconfont iconweizhi"></icon>联系地址：</p>
          <p>上海市嘉定区墨玉南路1018号</p>
        </div>
        <div class="footerBoxTopThree">
          <img src="@/assets/images/code.jpg" alt="" />
          <div class="footerBoxTopThreeBod">
            <p>官方微信：</p>
            <p>暮柏网</p>
            <p>关注官方微信，享受更多便民服务！</p>
          </div>
        </div>
      </div>
      <p>电话：400-119-0811 地址：上海市嘉定区墨玉南路1018号</p>
      <p>版权所有1997-2019 上海韬儒电子商务有限公司</p>
      <p><a href="http://beian.miit.gov.cn/">沪ICP备19004017号-2</a></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scope>
.footer {
  width: 100%;
  height: 478px;
  background: white;
  font-family: PingFangSC-Regular, PingFang SC;
  .footerT {
    height: 240px;
    width: 1120px;
    text-align: center;
    margin: auto;
  }
  .footerTView {
    color: #555;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    text-align: center;
  }
  .footerUl {
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
  }
  .footerUl {
    margin-top: 20px;
  }
  .footerUl > li {
    display: list-item;
    text-align: -webkit-match-parent;
    height: 60px;
  }
  .footerUl > li:not(:nth-child(1)) {
    margin-left: 30px;
  }
  .footerUl > li > a {
    color: #333;
    cursor: pointer;
  }
  .footerUl > li > a > img {
    vertical-align: middle;
    border: none;
    display: inline-block;
    // object-fit: cover;
    width: 100%;
    height: 100%;
  }
  // .footerUl>li:nth-child(1){
  //   vertical-align: middle;
  //   width:140px;
  //   height:60px;
  // }
  .footerBox {
    background: #ededee;
    width: 100%;
    height: 328px;
    margin: auto;
    padding-top: 40px;
    .footerBoxTop {
      width: 1120px;
      height: 100px;
      display: flex;
      margin: auto;
      justify-content: space-between;
      .footerBoxTopOne {
        margin-top: 24px;
        width: 180px;
        p:nth-child(1) {
          height: 24px;
          font-size: 18px;
          font-weight: 600;
          color: #1a1a1a;
          display: flex;
          align-items: center;
          letter-spacing: 1px;
          .icondianhua {
            font-size: 26px;
            font-weight: 600;
            margin-right: 12px;
          }
        }
        p:nth-child(2) {
          margin-top: 4px;
          margin-left: 27px;
          height: 24px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1a1a1a;
          line-height: 24px;
          letter-spacing: 1px;
        }
      }
      .footerBoxTopTwo {
        width: 355px;
        margin-top: 24px;
        p:nth-child(1) {
          height: 24px;
          font-size: 18px;
          font-weight: 600;
          color: #1a1a1a;
          display: flex;
          align-items: center;
          letter-spacing: 1px;
          .iconweizhi {
            font-size: 22px;
            font-weight: 600;
            margin-right: 12px;
          }
        }
        p:nth-child(2) {
          margin-top: 4px;
          margin-left: 35px;
          height: 24px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1a1a1a;
          line-height: 24px;
          letter-spacing: 1px;
          text-align: left;
        }
      }
      .footerBoxTopThree {
        width: 384px;
        display: flex;
        justify-content: space-between;
        img {
          width: 100px;
          height: 100px;
        }
        .footerBoxTopThreeBod {
          p:nth-child(1) {
            text-align: left;
            height: 24px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #1a1a1a;
            line-height: 24px;
            letter-spacing: 1px;
          }
          p:nth-child(2) {
            text-align: left;
            margin-top: 8px;
            height: 24px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1a1a1a;
            line-height: 24px;
            letter-spacing: 1px;
          }
          p:nth-child(3) {
            text-align: left;
            margin-top: 20px;
            height: 24px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1a1a1a;
            line-height: 24px;
            letter-spacing: 1px;
          }
        }
      }
    }
    p:nth-child(2) {
      margin-top: 36px;
    }
    p {
      text-align: center;
      margin-top: 4px;
      height: 24px;
      font-size: 12px;
      font-weight: 400;
      color: #838383;
      line-height: 24px;
    }
  }
}
</style>