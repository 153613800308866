<template>
  <!-- 导航栏 -->
  <div class="navsB">
    <div class="navs">
      <ul>
        <li></li>
        <li
          v-for="(item,idx) in navsList"
          :class="$route.name== item.name &&'acitve'"
          @click="routeToNew(item.path)"
        >{{item.name}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navsList: [
        {
          name: '首页',
          path: '/',
        },
        {
          name: '合作陵园',
          path: '/CooperativeCemetery',
        },
        {
          name: '地图找墓',
          path: '/MapSeekTomb',
        },
        {
          name: '在线商城',
          path: '/StoreOnline_tr',
        },
        {
          name: '积分商城',
          path: '/PointsMall',
        },
        // {
        //   name: '在线祭祀',
        //   path: '/sacrifice',
        // },
        {
          name: '公益活动',
          path: '/activitie',
        },
        {
          name: '个人中心',
          path: '/personalCenter',
        },
      ],
    }
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
.navsB {
  width: 100%;
  background-color: #ffffff;
}
.navs {
  width: 1120px;
  margin: auto;
  ul {
    display: flex;
    width: 1120px;
    justify-content: space-between;
    list-style: none;
    li {
      flex: 1;
      display: inline;
      line-height: 40px;
      text-align: right;
      cursor: pointer;
      font-size: 18px;
      font-weight: 400;
      &.acitve {
        color: #299654;
        font-weight: 600;
      }
    }
    li:hover {
      color: #299654;
      font-weight: 600;
    }
  }
}
</style>