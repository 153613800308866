<template>
  <!-- 导航栏 -->
  <div class="navs">
    <div class="navsBox">
      <div class="navsBoxTitle" @click="routeTo('/personalCenter')" :class="($route.name== '个人中心' || $route.meta.parent== '个人中心')&&'acitve'">个人中心</div>
      <Dropdown>
        <!-- <a href="javascript:void(0)"> -->
           <!-- style="color:#299654;font-weight: 600;" -->
          <div class="navsBoxTitle" :class="($route.name== '账号设置' || $route.meta.parent== '账号设置')&&'acitve'">
            账号设置
            <Icon type="ios-arrow-down"></Icon>
          </div>
        <!-- </a> -->
        <DropdownMenu slot="list" >
            <DropdownItem @click.native="routeClick('address')">收货地址</DropdownItem>
            <DropdownItem @click.native="routeClick('information')">账户信息</DropdownItem>
            <DropdownItem @click.native="routeClick('feedback')">问题反馈</DropdownItem>
            <DropdownItem @click.native="routeClick('aboutOur')">关于我们</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
     
    }
  },
  mounted() {},
  methods:{
    routeClick(val){
      switch(val){
        case 'address':
             this.$router.push({ path: '/setting', query: {'mess':0} });
             break;
        case 'information':
          this.$router.push({ path: '/setting', query: {'mess':1} });
          break;
        case 'feedback':
          this.$router.push({ path: '/setting', query: {'mess':2} });
          break;
        case 'aboutOur':
          this.$router.push({ path: '/setting', query: {'mess':3} });
          break;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.navs {
  width: 400px;
  // margin: auto;
  font-family: PingFangSC-Medium, PingFang SC;
  .navsBox{
    width: 240px;
    height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: black;
    line-height: 40px;
    letter-spacing: 1px;
    display: flex;
    justify-content: space-between;
  }
  .navsBoxTitle{
    cursor: pointer;
  }
  .navsBoxTitle:hover{
    color:#299654;
  }
  .acitve {
    color: #299654;
    font-weight: 600;
  }

}
</style>