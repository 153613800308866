<template>
  <div class="home">
    <!-- style="min-height:165px"  -->
    <div v-show="$route.name != '登录'">
      <Header>
        <div
          slot="mens"
          v-if="$route.name == '排行榜'"
        >
          <div class="rank">排行榜</div>
        </div>
        <div
          slot="mens"
          v-else-if="$route.meta.parent == '在线商城'"
        >
          <div class="rank">金牌店铺</div>
        </div>
        <div
          slot="mens"
          v-else-if="$route.name == '购物车' || $route.meta.parent == '购物车'"
        >
          <div class="rank">购物车</div>
        </div>
        <div
          slot="mens"
          v-else-if="$route.name == '个人中心' || $route.meta.parent == '个人中心' || $route.meta.parent == '账号设置'"
        >
          <NavsCenter />
        </div>
        <div slot="search">
          <InputRank v-if="$route.name == '排行榜'" />
          <InputGoId v-else-if="$route.meta.parent == '在线商城'" />
          <InputCenter v-else-if="$route.name == '个人中心' || $route.meta.parent == '个人中心'|| $route.meta.parent == '账号设置'" />
          <Input v-else />
        </div>
      </Header>
      <Navs v-if="$route.meta.parent == '首页'" />
    </div>
    <div class="main">
      <router-view  tag="a" target="_blank"/>
    </div>
    <Footer> </Footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Input from '@/components/list/Input'
import InputRank from '@/components/list/InputRank'
import InputGoId from '@/components/list/InputGoId'
import InputCenter from '@/components/list/InputCenter'
import NavsCenter from '@/components/list/NavsCenter'

import Navs from '@/components/Navs'
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Navs,
    Input,
    InputRank,
    InputGoId,
    InputCenter,
    NavsCenter,
  },
  data(){
    return{
   
    }
  },
  mounted(){

  },
  methods:{
 
  }
}
</script>
<style lang="less" scoped>
.Index {
  font-family: PingFangSC-Regular, PingFang SC;
  background-color: white;
}
// .home{
//   background-color: #ffffff;
// }
.rank {
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #838383;
  line-height: 33px;
  letter-spacing: 1px;
  margin-left: -60px;
}
</style>
