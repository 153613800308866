<template>
  <div class="box">
    <!-- <input v-model="value11" class="input"/>
      <Select v-model="select1" class="Select" placeholder="商品" >
          <Option value="http">商品1</Option>
          <Option value="https">商品2</Option>
      </Select>
      <div class="srea">搜索</div> -->
  </div>
</template>

<script>
export default {
   data() {
    return {
      value11:'',
      select1:'',
    }
  },
  mounted() {
  
  },
}
</script>

<style lang="less" scoped>
  /deep/.ivu-input{
      border: none!important;
    }
  input:focus{outline:none;}
  /deep/.ivu-select-single .ivu-select-selection {
    height: 28px;
    position: relative;
    border-radius: 0;
  }
  .Select{
    width: 73px;
    height: 10px !important;
    background: #EEEEEE;
    position: absolute;
    top:2px;
    left:2px;
    
  }
  .box{
    width: 500px;
    height: 32px;
    position: relative;
    font-family: PingFangSC-Medium, PingFang SC;
     .input{
      width: 500px;
      height: 32px;
      border-radius: 2px;
      border: 2px solid #299654;
      text-indent:80px;
      cursor: pointer;
     }
    .iconsousuo{
      color:#bababa;
      font-size:16px;
      position:absolute;
      top:4px;
      left:8px;
    }
    .srea{
        width: 80px;
        height: 32px;
        line-height:32px;
        text-align: center;
        background: #299654;
        border-radius: 0px 2px 2px 0px;
        position:absolute;
        top:0;
        right:0;
        font-size: 16px;      
        font-weight: 500;
        color: #FFFFFF;
        letter-spacing: 3px;
      }
      .logs{
        position:absolute;
        top:36;
        left:0;
        width:480px;
        display: flex;
        flex-wrap: nowrap;
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
        font-size: 12px;
      }
      .logs>span:nth-child(1){  
        color: #E64747;
      }
      .logs>span:not(:nth-child(1)){  
        margin-left:8px;
      }
  }
</style>